import React, { useState } from "react"
import UserBasicProfileForm from "../../../components/forms/UserBasicProfileForm"
import PageRoot from "../../../components/PageRoot"
import LoadingScreen from "../../../components/LoadingScreen"
import ErrorScreen from "../../../components/ErrorScreen"
import { FORM_TYPE_EDIT } from "../../../enums/FormTypes"
import { gql, useMutation, useQuery } from "@apollo/client"
import { translatePath, uiHandleError, uiHandleSuccess } from "../../../utils"
import { Spin } from "antd"
import ContainerWithPadding from "../../../components/ContainerWithPadding"
import { navigate } from "gatsby"
import { useTranslation } from "react-i18next"
import { PREFERENCE_TAGS } from "../../../enums/UserPreferenceKeys"

const GET_USER_PROFILE_QUERY = gql`
  query getUserBasicProfile {
    user_profiles {
      id
      user_id
      firstname
      lastname
      phone_number
      newsletter
      email
    }
    user_settings(where: { key: { _eq: "PREFERENCE_TAGS" } }) {
      key
      value
    }
  }
`

const UPDATE_USER_PROFILE_MUTATION = gql`
  mutation updateUserProfile(
    $profileData: user_profiles_insert_input!
    $userSetting: user_settings_insert_input!
  ) {
    insert_user_profiles_one(
      object: $profileData
      on_conflict: {
        constraint: user_profiles_user_id_key
        update_columns: [
          email
          address
          firstname
          lastname
          newsletter
          phone_number
        ]
      }
    ) {
      id
    }
    insert_user_settings_one(
      object: $userSetting
      on_conflict: { constraint: user_settings_pkey, update_columns: value }
    ) {
      key
    }
  }
`

const UserProfilePage = () => {
  const { t } = useTranslation()
  const { loading, error, data } = useQuery(GET_USER_PROFILE_QUERY, {
    fetchPolicy: "cache-and-network",
  })
  const [updateUserProfile] = useMutation(UPDATE_USER_PROFILE_MUTATION)
  const [updatingProfile, setUpdatingProfile] = useState(false)

  const pageTitle = t("label:myProfile")

  if (loading) {
    return (
      <PageRoot title={pageTitle} showTitle>
        <LoadingScreen />
      </PageRoot>
    )
  }

  if (error) {
    return (
      <PageRoot title={pageTitle} showTitle>
        <ErrorScreen error={error} />
      </PageRoot>
    )
  }

  let profile = data.user_profiles[0]

  if (!profile) {
    navigate(translatePath(`/auth/sign-up`))
    return null
  }

  profile = {
    ...profile,
    preferenceTags: JSON.parse(data.user_settings[0]?.value || "[]"),
  }

  const handleFormSubmit = async values => {
    setUpdatingProfile(true)
    try {
      const { preferenceTags, ...profileData } = values

      await updateUserProfile({
        variables: {
          profileData,
          userSetting: {
            key: PREFERENCE_TAGS,
            value: JSON.stringify(preferenceTags),
          },
        },
      })
      uiHandleSuccess({
        message: t("message:profileUpdated"),
        action: () => {},
      })
    } catch (error) {
      uiHandleError({ error })
    }
    setUpdatingProfile(false)
  }

  return (
    <PageRoot title={pageTitle} showTitle>
      <ContainerWithPadding size={"small"}>
        <Spin spinning={updatingProfile}>
          <UserBasicProfileForm
            initialValues={profile}
            formType={FORM_TYPE_EDIT}
            onFinish={handleFormSubmit}
          />
        </Spin>
      </ContainerWithPadding>
    </PageRoot>
  )
}

export default UserProfilePage
